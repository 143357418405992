import { CATALOGUE_IDS } from "src/constants/services";
import { orderActions } from "src/store/order";

export const ADDITIONAL_SERVICE_MAP = [
  ["SMS_TICKET", CATALOGUE_IDS.SMS_TICKET, orderActions.changeBookingSMSService],
  [
    "CONNECTION_PROTECTION_SERVICE",
    CATALOGUE_IDS.CONNECTION_PROTECTION_SERVICE,
    orderActions.changeConnectionProtection,
  ],
  ["MBG_SERVICE", CATALOGUE_IDS.MBG_SERVICE, orderActions.changeChangeProtection],
  [
    "ONLINE_CHECK-IN",
    CATALOGUE_IDS["ONLINE_CHECK-IN"],
    orderActions.changeOnlineCheckinService,
  ],
  ["AIRHELP_PLUS", CATALOGUE_IDS.AIRHELP_PLUS, orderActions.changeAirHelpService],
  ["SMS_MOBILE_ROUTE", CATALOGUE_IDS.SMS_MOBILE_ROUTE, orderActions.changeFlightsSMSService],
  [
    "lockInPriceService",
    CATALOGUE_IDS.lockInPriceService,
    orderActions.changePriceLockService,
  ],
  [
    "TRAVEL_BASIC",
    CATALOGUE_IDS.TRAVEL_INSURANCE.TRAVEL_BASIC,
    orderActions.changeTravelInsuranceType,
  ],
  [
    "TRAVEL_PLUS",
    CATALOGUE_IDS.TRAVEL_INSURANCE.TRAVEL_PLUS,
    orderActions.changeTravelInsuranceType,
  ],
  ["basic", CATALOGUE_IDS.SUPPORT.basic, orderActions.changeSupportServiceType],
  ["standart", CATALOGUE_IDS.SUPPORT.standart, orderActions.changeSupportServiceType],
  ["premium", CATALOGUE_IDS.SUPPORT.premium, orderActions.changeSupportServiceType],
  [
    "BAG_PROTECTION",
    CATALOGUE_IDS.BAG_PROTECTION,
    orderActions.changeCheckedBaggageProtection,
  ],
];

export const PROTECTION_SERVICES = {
  CONNECTION_PROTECTION_SERVICE: [
    orderActions.changeConnectionProtection,
    "connectionProtection",
  ],
  MBG_SERVICE: [orderActions.changeChangeProtection, "changeProtection"],
  TRAVEL_INSURANCE: [orderActions.changeTravelInsuranceType, "travelInsurance"],
};
