import { useEffect, useRef, useState } from "react";
import { useDeviceContext } from "src/context/device-context";
import classes from "./StepContent.module.css";
import ContactInformation from "../ContactInformation/ContactInformation";
import { PASSENGER } from "src/constants";
import { EMAIL_PATTERN } from "src/constants/validation";
import { DIGIT_PATTERN } from "src/constants/validation";
import { AGE_GROUP_MIN } from "src/constants/validation";
import { AGE_GROUP_MAX } from "src/constants/validation";
import { BOOKING_STEP } from "src/constants";
import { useLocaleContext } from "src/context/locale-context";
import BookingSignIn from "../BookingSignIn/BookingSignIn";
import ProtectionGuarantee from "../ProtectionGuarantee/ProtectionGuarantee";
import PassengerForm from "../PassengerForm/PassengerForm";
import BookingStepTitle from "../StepTitle/BookingStepTitle";
import BookingSidePanel from "../SidePanel/BookingSidePanel";
import {
  bookingActions,
  selectAllPassengers,
  selectContactData,
  selectTravelPackage,
} from "src/store/booking";
import { useDispatch, useSelector } from "react-redux";
import Button from "src/components/UI/Button/Button";
import { addPassenger, removePassenger, updatePassenger } from "src/store/actions";
import OrderDetails from "../OrderDetails/OrderDetails";
import { isValidDOB } from "src/utils/date-utils";
import { MobileBottomElement } from "../MobileBottomElement/MobileBottomElement";
import { useBookingFlow } from "src/context/booking-flow-context";
import { BookingNextButton } from "src/components/features/booking/StepContent/BookingNextButton";
import { BookingBackButton } from "src/components/features/booking/StepContent/BookingBackButton";
import { AnimatePresence, motion } from "framer-motion";
import { useAuth } from "src/context/auth-context";
import { nanoid } from "@reduxjs/toolkit";

export function isConnectionProtectionEligible(travelPackage) {
  const isEligible =
    travelPackage.is_vtrip &&
    travelPackage.data_provider === "MA" &&
    travelPackage.segments.length > 2;
  return isEligible;
}

function PassengerStepContent({ pageId }) {
  const { isMobile } = useDeviceContext();
  const { stringRes } = useLocaleContext();
  const { isAuthenticated } = useAuth();
  const { goToNextBookingPage, goToPrevBookingPage, hasPrevious, hasNext } = useBookingFlow();
  const dispatch = useDispatch();
  const currentPaxs = useSelector(selectAllPassengers);
  const contactData = useSelector(selectContactData);

  const [isInit, setIsInit] = useState(true);

  const protectionService = useSelector((state) => state.order.connectionProtection.serviceId);
  const travelPackage = useSelector(selectTravelPackage);
  const isConnectionProtectEligible = isConnectionProtectionEligible(travelPackage);

  useEffect(() => {
    if (isInit) setIsInit(false);
  }, [isInit]);

  const contactRef = useRef();
  const serviceRef = useRef();
  const passengersRef = useRef({});

  const onGoToNextStep = () => {
    const matchesRequired = currentPaxs.length;
    let warningElements = [undefined, undefined];

    let isValidContactData, isValidProtectionService;
    // validate contact data
    if (EMAIL_PATTERN.test(contactData.email) && DIGIT_PATTERN.test(contactData.tel)) {
      isValidContactData = true;
    } else {
      warningElements[isMobile ? 1 : 0] = contactRef.current;
    }

    // validate connection protection service
    if (protectionService || !isConnectionProtectEligible) {
      isValidProtectionService = true;
    } else {
      warningElements[isMobile ? 0 : 1] = serviceRef.current;
    }

    let matchesFound = 0;
    currentPaxs.forEach((passenger) => {
      let isValidPersonalData;

      // validate baggage selection
      // const paxBaggage = baggageData.find((b) => b.passengerId === passenger.id);
      // if (passenger.ageGroup === PASSENGER.infant) {
      //   isValidBaggage = true;
      // } else {
      //   isValidBaggage =
      //     paxBaggage.cabin.serviceId &&
      //     ((!passenger.skipCheckedBaggage && paxBaggage.checked.serviceId) ||
      //       passenger.skipCheckedBaggage);
      // }

      // validate paseenger info
      if (
        passenger.name &&
        passenger.surname &&
        passenger.nationality &&
        passenger.gender &&
        isValidDOB(
          passenger.dob,
          AGE_GROUP_MAX[passenger.ageGroup],
          AGE_GROUP_MIN[passenger.ageGroup]
        )
      ) {
        isValidPersonalData = true;
      }

      if (isValidPersonalData) {
        matchesFound += 1;
      } else {
        warningElements.push(passengersRef[passenger.id]);
      }
    });

    if (matchesFound === matchesRequired && isValidContactData && isValidProtectionService) {
      dispatch(bookingActions.resetFormState(BOOKING_STEP.passengerDetailsForm.id));
      dispatch(
        bookingActions.setPassengerState({
          committedPaxs: true,
          paxsChangedSinceCommit: false,
        })
      );
      // go to next step
      goToNextBookingPage(pageId);
    } else {
      dispatch(
        bookingActions.updateFormWarningStatus({
          step: BOOKING_STEP.passengerDetailsForm.id,
          isIncomplete: true,
        })
      );
      dispatch(
        bookingActions.incrementValidationAttempts(BOOKING_STEP.passengerDetailsForm.id)
      );
      warningElements = warningElements.filter((we) => !!we);
      warningElements[0].scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const onRemovePassenger = (passenger) => {
    dispatch(removePassenger(passenger.id, passenger.ageGroup));
  };

  const onAddPassenger = () => {
    dispatch(addPassenger(PASSENGER.adult, nanoid()));
  };

  const setPassengerAgeGroup = (passenger, group) => {
    dispatch(updatePassenger(passenger.id, PASSENGER[group], { ...passenger }));
  };

  const canRemovePassenger = (index, ageGroup) => {
    let infantCount = currentPaxs.filter((p) => p.ageGroup === PASSENGER.infant).length;
    let adultCount = currentPaxs.filter((p) => p.ageGroup === PASSENGER.adult).length;
    return index > 0 && (ageGroup !== PASSENGER.adult || adultCount > infantCount);
  };

  const canChangePassengerToInfant = (ageGroup) => {
    let infantCount = currentPaxs.filter((p) => p.ageGroup === PASSENGER.infant).length;
    let adultCount = currentPaxs.filter((p) => p.ageGroup === PASSENGER.adult).length;
    if (ageGroup === PASSENGER.adult) {
      return adultCount - 1 >= infantCount + 1;
    } else if (ageGroup === PASSENGER.child) {
      return adultCount > infantCount;
    } else if (ageGroup === PASSENGER.infant) {
      return true;
    }
  };

  const createPassengerForm = (pax, i) => {
    return (
      <PassengerForm
        ref={passengersRef}
        key={pax.id}
        isInit={isInit}
        passenger={pax}
        passengerNum={i + 1}
        enableInfantType={canChangePassengerToInfant(pax.ageGroup)}
        onRemove={canRemovePassenger(i, pax.ageGroup) ? () => onRemovePassenger(pax) : null}
        onChangeAgeGroup={(group) => setPassengerAgeGroup(pax, group)}
      />
    );
  };

  if (!isMobile) {
    return (
      <section className={`${classes.container} ${classes.desktop}`}>
        <BookingStepTitle step={pageId} />
        <div className={classes.main}>
          <AnimatePresence>{!isAuthenticated && <BookingSignIn />}</AnimatePresence>
          <ContactInformation ref={contactRef} />
          {isConnectionProtectEligible && <ProtectionGuarantee ref={serviceRef} />}
          <AnimatePresence mode="popLayout">
            {currentPaxs.map((pax, i) => createPassengerForm(pax, i))}
          </AnimatePresence>
          {currentPaxs.length < 9 && (
            <div className={classes.addPassenger}>
              <h1>{stringRes["booking.form.passenger.add.title"]}</h1>
              <Button
                name={stringRes["booking.form.passenger.add.button"]}
                onClick={onAddPassenger}
              />
            </div>
          )}
        </div>
        <BookingSidePanel showLockPrice={false} />
        <div className={classes.nextButton}>
          {hasPrevious(pageId) ? (
            <BookingBackButton
              onClick={() => goToPrevBookingPage(pageId)}
              className={classes.withBackArr}
              buttonText={stringRes["booking.back.button"]}
            />
          ) : (
            <div></div>
          )}
          {hasNext(pageId) ? (
            <BookingNextButton
              onClick={onGoToNextStep}
              className={classes.withArr}
              buttonText={stringRes["booking.continue.button"]}
            />
          ) : (
            <div></div>
          )}
        </div>
      </section>
    );
  } else {
    return (
      <section className={`${classes.container} ${classes.mobile}`}>
        <div className={classes.row}>
          <BookingStepTitle step={pageId} />
          <OrderDetails />
          {isConnectionProtectEligible && <ProtectionGuarantee ref={serviceRef} />}
        </div>
        <div className={classes.row}>
          <ContactInformation ref={contactRef} />
        </div>
        <AnimatePresence mode="sync">
          {currentPaxs.map((pax, i) => (
            <motion.div key={"container" + pax.id} className={classes.row}>
              {createPassengerForm(pax, i)}
            </motion.div>
          ))}
        </AnimatePresence>
        {currentPaxs.length < 9 && (
          <div className={classes.row}>
            <div className={classes.addPassenger}>
              <h1>{stringRes["booking.form.passenger.add.title"]}</h1>
              <Button
                name={stringRes["booking.form.passenger.add.button"]}
                onClick={onAddPassenger}
                wide={isMobile}
              />
            </div>
          </div>
        )}
        <div className={classes.row}>
          <MobileBottomElement onGoToNextStep={onGoToNextStep} withPrice />
        </div>
      </section>
    );
  }
}

export default PassengerStepContent;
