import { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CATALOGUE_IDS, PRICE_POLICY, SERVICE_TYPE } from "src/constants/services";
import { STATIC_ELEMENT } from "src/constants/static";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";
import { MODAL_TYPE, useModalContext } from "src/context/modal-stack-context";
import useStaticContent from "src/hooks/useStaticContent";
import { selectTravelPackage } from "src/store/booking";
import { selectCatalogue, selectCatalogueItem } from "src/store/catalogue";
import { orderActions } from "src/store/order";
import { splitStringByNewline } from "src/utils/string-utils";
import classes from "./ExtraServicesSelection.module.css";
import { ExtraServiceOptionDesktop } from "./elements/ExtraServiceOptionDesktop";
import { ExtraServiceOptionMobile } from "./elements/ExtraServiceOptionMobile";

function getPolicyStrKeys(data, isVTrip) {
  const policy = data.policy;
  const vtrip_policy = data.vtrip_policy;

  let policies = Object.keys(PRICE_POLICY)
    .filter((k) => Object.keys(policy).includes(k))
    .map((k) => PRICE_POLICY[k]);
  if (isVTrip && vtrip_policy) {
    let vpolicies = Object.keys(PRICE_POLICY)
      .filter((k) => Object.keys(vtrip_policy).includes(k))
      .map((k) => PRICE_POLICY[k]);
    policies = policies.concat(vpolicies);
  }

  return policies;
}

const ExtraServicesSelection = forwardRef((props, ref) => {
  const { isMobile } = useDeviceContext();
  const { stringRes } = useLocaleContext();
  const { openModal } = useModalContext();
  const dispatch = useDispatch();

  const { html: sms_template } = useStaticContent(STATIC_ELEMENT.sms);
  const { html: airhelp_template } = useStaticContent(STATIC_ELEMENT.airhelp);
  const { html: checkin_template } = useStaticContent(STATIC_ELEMENT.checkin);

  const travelPackage = useSelector(selectTravelPackage);
  const checkInService = useSelector((state) => state.order.onlineCheckin.serviceId);
  const flightSMSService = useSelector((state) => state.order.flightsSMSService.serviceId);
  const airHelpService = useSelector((state) => state.order.airHelpService.serviceId);

  const checkinData = useSelector((state) =>
    selectCatalogueItem(selectCatalogue(state), CATALOGUE_IDS["ONLINE_CHECK-IN"])
  );
  const flightSMSData = useSelector((state) =>
    selectCatalogueItem(selectCatalogue(state), CATALOGUE_IDS.SMS_MOBILE_ROUTE)
  );
  const airHelpData = useSelector((state) =>
    selectCatalogueItem(selectCatalogue(state), CATALOGUE_IDS.AIRHELP_PLUS)
  );

  const checkInPrice = checkinData.unit_price;
  const flightSMSPrice = flightSMSData.unit_price;
  const airHelpPrice = airHelpData.unit_price;

  const checkInPolicies = getPolicyStrKeys(checkinData.data, travelPackage.is_vtrip);
  const flightSMSPolicies = getPolicyStrKeys(flightSMSData.data, travelPackage.is_vtrip);
  const airHelpPolicies = getPolicyStrKeys(airHelpData.data, travelPackage.is_vtrip);

  const onToggleAirHelp = () => {
    const newServiceId =
      airHelpService === CATALOGUE_IDS.AIRHELP_PLUS
        ? SERVICE_TYPE.REFUSED
        : CATALOGUE_IDS.AIRHELP_PLUS;
    dispatch(orderActions.changeAirHelpService(newServiceId));
  };

  const onToggleOnlineCheckin = () => {
    const newServiceId =
      checkInService === CATALOGUE_IDS["ONLINE_CHECK-IN"]
        ? SERVICE_TYPE.REFUSED
        : CATALOGUE_IDS["ONLINE_CHECK-IN"];
    dispatch(orderActions.changeOnlineCheckinService(newServiceId));
  };

  const onToggleFlightsSMSService = () => {
    const newServiceId =
      flightSMSService === CATALOGUE_IDS.SMS_MOBILE_ROUTE
        ? SERVICE_TYPE.REFUSED
        : CATALOGUE_IDS.SMS_MOBILE_ROUTE;
    dispatch(orderActions.changeFlightsSMSService(newServiceId));
  };

  const checkInFeatures = splitStringByNewline(
    stringRes["booking.extras.option.checkin.features"]
  );
  const smsServiceFeatures = splitStringByNewline(
    stringRes["booking.extras.option.sms.features"]
  );
  const airHelpFeatures = splitStringByNewline(
    stringRes["booking.extras.option.airhelp.features"]
  );

  const getTemplate = (serviceId) => {
    switch (serviceId) {
      case CATALOGUE_IDS.AIRHELP_PLUS:
        return airhelp_template;
      case CATALOGUE_IDS["ONLINE_CHECK-IN"]:
        return checkin_template;
      case CATALOGUE_IDS.SMS_MOBILE_ROUTE:
        return sms_template;
      default:
        break;
    }
  };

  const onShowServiceDetails = (event) => {
    const serviceId = event.currentTarget.value;
    let template = getTemplate(serviceId);
    openModal(MODAL_TYPE.serviceDetails, { template });
  };

  const Component = isMobile ? ExtraServiceOptionMobile : ExtraServiceOptionDesktop;
  const childProps = {
    checkin: {
      isSelected: checkInService === CATALOGUE_IDS["ONLINE_CHECK-IN"],
      serviceId: CATALOGUE_IDS["ONLINE_CHECK-IN"],
      price: checkInPrice,
      description: isMobile && stringRes["booking.extras.option.checkin.description"],
      name: stringRes["booking.extras.option.checkin"],
      onSelect: onToggleOnlineCheckin,
      priceBasis: checkInPolicies.map((strKey) => stringRes[strKey]).join(" "),
      disableListView: isMobile,
      features: !isMobile && checkInFeatures,
      tagText: !isMobile && stringRes["booking.change.guarantee.tag"],
    },
    flightSMS: {
      isSelected: flightSMSService === CATALOGUE_IDS.SMS_MOBILE_ROUTE,
      serviceId: CATALOGUE_IDS.SMS_MOBILE_ROUTE,
      price: flightSMSPrice,
      description: isMobile && stringRes["booking.extras.option.sms.description"],
      name: stringRes["booking.extras.option.sms"],
      features: !isMobile && smsServiceFeatures,
      onSelect: onToggleFlightsSMSService,
      priceBasis: flightSMSPolicies.map((strKey) => stringRes[strKey]).join(" "),
      disableListView: isMobile,
    },
    airHelp: {
      isSelected: airHelpService === CATALOGUE_IDS.AIRHELP_PLUS,
      serviceId: CATALOGUE_IDS.AIRHELP_PLUS,
      price: airHelpPrice,
      name: stringRes["booking.extras.option.airhelp"],
      features: airHelpFeatures,
      onSelect: onToggleAirHelp,
      priceBasis: airHelpPolicies.map((strKey) => stringRes[strKey]).join(" "),
    },
  };
  const commonProps = { onShowDetails: onShowServiceDetails };

  return (
    <div
      ref={ref}
      className={`${classes.container} ${classes[isMobile ? "mobile" : "desktop"]}`}>
      <Component {...childProps.checkin} {...commonProps} />
      <Component {...childProps.flightSMS} {...commonProps} />
      <Component {...childProps.airHelp} {...commonProps} />
    </div>
  );
});

export default ExtraServicesSelection;
