import Button from "src/components/UI/Button/Button";
import classes from "./LockPrice.module.css";
import { useLocaleContext } from "src/context/locale-context";
import { useDeviceContext } from "src/context/device-context";
import { useDispatch, useSelector } from "react-redux";
import { selectCatalogue, selectServicePrice } from "src/store/catalogue";
import { CATALOGUE_IDS, SERVICE_TYPE } from "src/constants/services";
import { orderActions } from "src/store/order";
import { getDisplayPriceValue } from "src/utils/utils";

function LockPrice() {
  const { currencySymbol, stringRes } = useLocaleContext();
  const { isMobile } = useDeviceContext();
  const dispatch = useDispatch();
  const lockInPrice = useSelector((state) =>
    selectServicePrice(selectCatalogue(state), CATALOGUE_IDS.lockInPriceService)
  );

  const onPriceLock = () => {
    dispatch(orderActions.changePriceLockService(CATALOGUE_IDS.lockInPriceService));
  };

  const onRemovePriceLock = () => {
    dispatch(orderActions.changePriceLockService(SERVICE_TYPE.NOT_SELECTED));
  };

  return (
    <div
      className={`${classes.container} ${isMobile ? classes.mobile : classes.desktop}`}
    >
      <h1>{stringRes["booking.order.price.lockin.title"]}</h1>
      <img className={classes.image} src="https://placehold.co/132x76" alt="lock price" />
      <p className={classes.description}>
        {stringRes["booking.order.price.lockin.description"]}
      </p>
      <Button
        onClick={onPriceLock}
        name={`${stringRes["booking.order.price.lockin.button"]} ${getDisplayPriceValue(
          lockInPrice
        )} ${currencySymbol}`}
      />
    </div>
  );
}

export default LockPrice;
